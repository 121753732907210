import React from 'react'
import { CompanyTable } from '../components/CTable'

export const Companies =()=>{
    return(
        <div>
            <h2>Companies</h2>
           <CompanyTable/>
        </div>
    )
}