import { useState, useEffect } from 'react';
import SubEditor from './subMainEditor';
import { useHistory } from "react-router-dom";
import {  useGetCategoryQuery, useGetAuthorQuery, useGetPostQuery, useEditPostMutation } from '../../reactWrapper/Redux/apiSlice';

const EditPage =({ postId }) => {

    const { data: post } = useGetPostQuery(postId)
    console.log('the post here', post)
    const [editPost, { isLoading: isPosting }] = useEditPostMutation()

    const { data: category,  isError: errCat, error: errCatMsg } = useGetCategoryQuery();
    console.log('category data', category);
    const { data: authors, isLoading: loadingAut, isError: errAut, error: errAutMsg } = useGetAuthorQuery();
    console.log('category data', authors);

    const history = useHistory();

    const [title, setTitle] = useState([post[0].title]);
    const [category_id, setCategory_Id] = useState([post[0].category_id]);
    // const [content, setContent] = useState([post[0].content]);
    const [featured_image_link, setFeatured_Image_Link] = useState([post[0].featured_image_link]);
    const [featured_image_links, setFeatured_Image_Links] = useState([post[0].featured_image_link]);
    const [authors_name, setAuthors_Name] = useState([post[0].authors_name]);
    const [meta_description, setMeta_Description] = useState([post[0].meta_description]);
    const [key_phrase, setKey_Phrase] = useState([post[0].key_phrase]);

    useEffect(() => {
        console.log('data', category)
    }, [category])

    useEffect(() => {
        console.log('data', authors)
    }, [authors])

    const handleSubmit = async (e) => {
        e.preventDefault();
        var formdata = new FormData();

        formdata.append("id", postId);
        formdata.append("title", title);
        formdata.append("authors_name", authors_name);
        formdata.append("featured_image_link", featured_image_link);
        formdata.append("category_id", category_id);
        formdata.append("meta_description", meta_description);
        formdata.append("key_phrase", key_phrase);

        await editPost(formdata)
        history.push(`/mypost/${postId}`)
    };
    // const [message, setMessage] = useState(true)

    // const handleClick = (e) =>{
    //     e.preventDefault();
    //     setMessage(!message)
    // }

    const handleChange = (event) => {
        // if (event.target.files && event.target.files[0]) {
        setFeatured_Image_Links(URL.createObjectURL(event.target.files[0]));
        // console.log('this is the full image', featured_image_link)
        setFeatured_Image_Link(event.target.files[0])
        console.log('this is the full image', featured_image_link)
        // }
    };
    
    return (
        <>
            <form onSubmit={handleSubmit}>
            <div className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                        <div className="p-6 bg-white border-b border-gray-200">
                           

                            <div className="mb-4">
                                <label class="text-xl text-gray-600">Blog Image <span class="text-red-500">*</span></label>
                                <br/>
                                <input type="file" className="border-2 border-gray-300 p-2" onChange={(e) => handleChange(e)}  />
                                {featured_image_links &&
                                    <div className="h-60">
                                        <img src={featured_image_links} alt="preview view" className="object-cover w-full h-full"/>
                                    </div>
                                }
                            </div>                          

                            <div className="mb-4">
                                <label class="text-xl text-gray-600">Title <span class="text-red-500">*</span></label>
                                <br/>
                                <input type="text" className="border-2 border-gray-300 p-2 w-full" name="title" id="title" value={title} onChange={(e)=> setTitle(e.target.value)} required />
                            </div>

                            <div className="mb-4">
                                <label class="text-xl text-gray-600">key Phrases <span class="text-red-500">*</span></label>
                                <br/>
                                <input type="text" className="border-2 border-gray-300 p-2 w-full" name="key_phrase" id="key_phrase" value={key_phrase} onChange={(e)=> setKey_Phrase(e.target.value)} required />
                            </div>

                            <div className="mb-4">
                                <label class="text-xl text-gray-600">Choose Category <span class="text-red-500">*</span></label>
                                <select 
                                value={category_id} 
                                onChange={(e) => setCategory_Id(e.target.value)}
                                className="border-2 border-gray-300 border-r p-2" name="action">
                                    {category && category.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                        )                                      
                                    )}
                                    {errCat && <div> {errCatMsg.toString()} </div>}
                                    
                                </select>
                            </div>

                            <div className="mb-4">
                                <label class="text-xl text-gray-600">Select Author <span class="text-red-500">*</span></label>
                                <select 
                                 value={authors_name} 
                                 onChange={(e)=> setAuthors_Name(e.target.value)}
                                 className="border-2 border-gray-300 border-r p-2" name="action">
                                    {loadingAut && <div> Loading </div>}
                                    {authors && authors.map((item) =>(
                                        
                                        <option key={item.id}>
                                            {item.name}
                                        </option>                                                            
                                    ))}
                                    {errAut && <div> {errAutMsg.toString()} </div>}
                                </select>
                            </div>

                            <div className="mb-8">
                                <label className="text-xl text-gray-600">Meta Description <span class="text-red-500">*</span></label>
                                <br/>
                                <textarea name="content" className="border-2 border-gray-500 w-full px-3 py-5 box-border text-base" value={meta_description} onChange={(e)=> setMeta_Description(e.target.value)} required />
                                
                            </div>
                          
                            {/* <SubEditor content={content} setContent={setContent} /> */}

                            <div className="flex p-1">
                                <select className="border-2 border-gray-300 border-r p-2" name="action">
                                    <option>Save and Publish</option>
                                    <option>Save Draft</option>
                                </select>
                                {isPosting
                                    ? <button className="p-3 bg-blue-500 text-white hover:bg-blue-400" >updating...</button>
                                    : <button className="p-3 bg-blue-500 text-white hover:bg-blue-400" >update</button> 
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </form>            
        </>
        
    )
}

export default EditPage;


