import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom';
import { Header } from './components/Layouts/Header';
import { SideBar } from './components/Layouts/Sidebar';
import {Companies} from './Pages/Companys'
import {Developers} from './Pages/Developers'
import {Projects} from './Pages/Projects'
import {ProjectManagers} from './Pages/ProjectManager'
import { EditProject } from './Pages/EditProject';
import {companyGigList } from './Pages/Gigs';
import { AllBidders } from './Pages/Bidders';
import { Blog } from './Pages/Blog';
import MyPost from './Pages/MyPost'
import SelectCatAut from './Pages/SelectCatAut';
import { SingleBlog } from './Pages/BlogDetails'; 
import EditBlog from './Pages/EditPost';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"


function App() {
  return (
    <>
    <div className="flex flex-row min-h-screen bg-gray-100 text-gray-800">
    
    <BrowserRouter>
        <ToastContainer />
        <SideBar/>
        <Header>
        <div className="mt-6 mx-3">     
        <Route path="/companies" exact component={Companies} />
        <Route path= "/developers" exact component={Developers} />
        <Route path= "/projects" exact component={Projects} />
        <Route path="/projectmanagers" exact component={ProjectManagers} />
        <Route path="/postblog" exact component={Blog} />
        <Route path="/mypost" exact component={MyPost} />
        <Route path="/createauthorcategory" exact component={SelectCatAut} />
        <Route path= "/project/update/:id" exact component={EditProject} />
        <Route path= "/company/gigs/:id" exact component={companyGigList} />
        <Route path= "/project/bidders/:id" exact component={AllBidders}/>
        <Route path= "/mypost/:postId" exact component={SingleBlog}/>
        <Route path= "/editpost/:postId" exact component={EditBlog}/>

        </div>
        </Header>
      </BrowserRouter>
   

   
    </div>
  
  </>
  );
}

export default App;

