import React, {useState} from 'react'

const initialValues = {
  fprice1: '',
  fprice2: '',
  Sprice1: '',
  Sprice2: '',
  Bkprice1: '',
  Bkprice2:''   
}
export const PriceRange =()=>{
     const [values, setValues] = useState(initialValues)

     const handleInputChange=(e)=>{
     const {name, value}= e.target
       setValues({
         ...values,
         [name]: value
       })
     
     }
    return(
        <div>
           <div className="col-span-6 sm:col-span-3">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">Team Type
            </label>
            <select name="team" autoComplete="country" className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
              <option value='Expert'>Expert</option>
              <option value='regular'>Regular</option>
              </select>
            </div>
           <p>Price Range</p>
           <div className="grid grid-cols-6 gap-6">
           <div className="col-span-6 sm:col-span-6 lg:col-span-2">
          <label htmlFor="city" className="block text-sm font-medium text-gray-700">Frontend</label>
          <input onChange={handleInputChange} type="text" name="frontEndTop" id="city" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"/>to
          <input  onChange={handleInputChange} type="text" name="frontEndBottom" id="city" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"/>
           </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <label htmlFor="city" className="block text-sm font-medium text-gray-700">Backend</label>
          <input  onChange={handleInputChange} type="text" name="backEndTop" id="city" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"/>to
          <input  onChange={handleInputChange} type="text" name="backEndBottom" id="city" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"/>
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <label htmlFor="city" className="block text-sm font-medium text-gray-700">UI/UX</label>
          <input  onChange={handleInputChange} type="text" name="ui_uxTop" id="city" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"/>to
          <input  onChange={handleInputChange} type="text" name="ui_uxBottom" id="city" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"/>        
          </div>
          </div>

          <div className="grid grid-cols-6 gap-6">
           <div className="col-span-6 sm:col-span-6 lg:col-span-2">
          <label htmlFor="city" className="block text-sm font-medium text-gray-700">Mobile App</label>
          <input onChange={handleInputChange} type="text" name="mobileAppTop" id="city" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"/>to
          <input  onChange={handleInputChange} type="text" name="mobileAppBottom" id="city" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"/>
           </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <label htmlFor="city" className="block text-sm font-medium text-gray-700">Desktop</label>
          <input  onChange={handleInputChange} type="text" name="desktopTop" id="city" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"/>to
          <input  onChange={handleInputChange} type="text" name="desktopBottom" id="city" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"/>
          </div>

          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
          <label htmlFor="city" className="block text-sm font-medium text-gray-700">TOTAL Charge</label>
         <input onChange={handleInputChange} type="text" name="totalCharge" id="city" className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border border-gray-300 rounded-md"/>
          </div>
          </div>
          
        </div>
    )
}