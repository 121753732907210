import React from 'react'
import { Bidding } from '../components/BidTable'

export const AllBidders =()=>{
    return(
        <div>
            
            <Bidding/>
        </div>
    )
}

