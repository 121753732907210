import React from 'react'
import { BasicDetails } from './BasicDetails'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { PriceRange } from './PriceRange'
import EasyHttp from '../../helper/easyHttp'
const simpleHttp =  new EasyHttp()

export const Wrapper =()=>{

  const handleSubmit=async(e)=>{
  e.preventDefault()
  const teamType = e.target.team.value
  const front_end_price_top = e.target.frontEndTop.value
  const front_end_price_bottom = e.target.frontEndBottom.value
  const back_end_price_top = e.target.backEndTop.value
  const back_end_price_bottom =e.target.backEndBottom.value
  const ui_ux_price_top = e.target.ui_uxTop.value
  const ui_ux_price_bottom =e.target.ui_uxBottom.value
  const mobile_app_price_top = e.target.mobileAppTop.value
  const mobile_app_price_bottom =e.target.mobileAppBottom.value
  const desktop_price_top = e.target.desktopTop.value
  const desktop_price_buttom = e.target.desktopBottom.value
  const totalCharge = e.target.totalCharge.value
  const uid =  window.location.pathname.slice(16)
  const data = {teamType,front_end_price_top,front_end_price_bottom,back_end_price_top,back_end_price_bottom,ui_ux_price_top,ui_ux_price_bottom,mobile_app_price_top,mobile_app_price_bottom,desktop_price_top,desktop_price_buttom,totalCharge,uid}
  const res = await simpleHttp.post('/api/v1/admin/projectSettings',data)
  if(res.status === true){
  toast.info(res.message)
  // dispatch(hydrateProfile(res.data))
   }else{toast.error(res.message)}
   }
    return( 
      <>

     <form onSubmit={handleSubmit}>
      <div className="shadow overflow-hidden sm:rounded-md">
         <div className="px-4 py-5 bg-white sm:p-6">
          <BasicDetails/>
         <div className="hidden sm:block" aria-hidden="true">
        <div className="py-5">
          <div className="border-t border-gray-200" />
        </div>
      </div>
          <PriceRange/>
         </div>
         <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Save
          </button>
          </div>
      </div>

          </form>

<ToastContainer
      position="top-right"
      autoClose={10000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      /> 
      </> 
 
    )
}