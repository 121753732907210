import React, {useState} from 'react';
import Modal from './modalPost';
import { Link } from 'react-router-dom'


const BlogPost = ({ blogItems }) => {
    // const dispatch = useDispatch();
    const [show, setShow] = useState(false);

    const modalClick=(e) =>{
        e.preventDefault()
        setShow(!show)
    }    

    return(
        <>
            
            <section className="text-gray-600 body-font">
                <div className="container px-5 py-24 mx-auto">
                    <div className="flex flex-col text-center w-full mb-20">
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Master Cleanse Reliac Heirloom</h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Whatever cardigan tote bag tumblr hexagon brooklyn asymmetrical gentrify, subway tile poke farm-to-table. Franzen you probably haven't heard of them man bun deep jianbing selfies heirloom prism food truck ugh squid celiac humblebrag.</p>
                    </div>
                    <div className="flex flex-wrap -m-4 text-center">
                    <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                        <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                            <path d="M8 17l4 4 4-4m-4-5v9"></path>
                            <path d="M20.88 18.09A5 5 0 0018 9h-1.26A8 8 0 103 16.29"></path>
                        </svg>
                        <h2 className="title-font font-medium text-3xl text-gray-900">2.7K</h2>
                        <p className="leading-relaxed">Downloads</p>
                        </div>
                    </div>
                    <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                        <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                            <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
                            <circle cx="9" cy="7" r="4"></circle>
                            <path d="M23 21v-2a4 4 0 00-3-3.87m-4-12a4 4 0 010 7.75"></path>
                        </svg>
                        <h2 className="title-font font-medium text-3xl text-gray-900">1.3K</h2>
                        <p className="leading-relaxed">Users</p>
                        </div>
                    </div>
                    <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                        <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                            <path d="M3 18v-6a9 9 0 0118 0v6"></path>
                            <path d="M21 19a2 2 0 01-2 2h-1a2 2 0 01-2-2v-3a2 2 0 012-2h3zM3 19a2 2 0 002 2h1a2 2 0 002-2v-3a2 2 0 00-2-2H3z"></path>
                        </svg>
                        <h2 className="title-font font-medium text-3xl text-gray-900">74</h2>
                        <p className="leading-relaxed">Files</p>
                        </div>
                    </div>
                    <div className="p-4 md:w-1/4 sm:w-1/2 w-full">
                        <div className="border-2 border-gray-200 px-4 py-6 rounded-lg">
                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="text-indigo-500 w-12 h-12 mb-3 inline-block" viewBox="0 0 24 24">
                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                        </svg>
                        <h2 className="title-font font-medium text-3xl text-gray-900">46</h2>
                        <p className="leading-relaxed">Places</p>
                        </div>
                    </div>
                    </div>
                </div>
                </section>

            {blogItems.map((blog) => {
                return(
                    <div className="max-w-2xl px-8 py-4 mx-auto bg-white rounded-lg shadow-md dark:bg-gray-800 mb-4" key={blog.id}>
                        <div className="flex items-center justify-between">
                            <span className="text-sm font-light text-gray-600 dark:text-gray-400">{blog.created_at}</span>
                            <button className="px-3 py-1 text-sm font-bold text-gray-100 transition-colors duration-200 transform bg-gray-600 rounded cursor-pointer hover:bg-gray-500"
                            onClick={modalClick} 
                            // onClick={()=>
                            //     dispatch(deleteBlog( blog.id ))              
                            // }
                            >
                                Delete Post
                            </button>
                        </div>
                        <Modal onClose={() => setShow(false)} show={show} deleteId={blog.id} />

                        <div className="mt-2">
                            <a href="./../pages/Blog" className="text-2xl font-bold text-gray-700 dark:text-white hover:text-gray-600 dark:hover:text-gray-200 hover:underline">{blog.title}</a>
                            <p className="mt-2 text-gray-600 dark:text-gray-300">{blog.meta_description}</p>
                        </div>
                        
                        <div className="flex items-center justify-between mt-4">
                            <Link to ={`/mypost/${blog.id}`} >
                                <p className="text-blue-600 dark:text-blue-400 hover:underline">
                                    Read more
                                </p>
                            </Link>

                            <div className="flex items-center">
                                <img className="hidden object-cover w-10 h-10 mx-4 rounded-full sm:block" src={blog.featured_image_link} alt={blog.title} />
                                <a href="./../pages/Blog" className="font-bold text-gray-700 cursor-pointer dark:text-gray-200">{blog.authors_name}</a>
                            </div>
                        </div>
                    </div>
                );
            }
            )};

            
       </>
    )
}

export default BlogPost;