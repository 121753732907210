import React from 'react'
import {selectCompany,selectProjects} from '../../reactWrapper/Redux/userProfileSlice'
import { useSelector} from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import Moment from 'moment'
// import ClientOnlyPortal from '../Pages/ClientOnlyPortal'



export const BasicDetails =()=>{

// const companyData = useSelector(selectCompany)
// const companyProjects = companyData.data[0].companyProject

const allProjects = useSelector(selectProjects)
const companyProjects = allProjects == null?[]:allProjects.data
const uuid = window.location.pathname.slice(16)
let theProject = companyProjects.find(single => single.uuid === uuid)

    return(
        <>
        <div className="grid grid-cols-6 gap-6">
        <div className="col-span-6 sm:col-span-3">
        <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">Title</label>
        <input
        type="text"
        name="first-name"
        id="first-name"
        disabled ={true}
        value={theProject.name}
        autoComplete="given-name"
        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
        />
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                        Start Date
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        value={Moment(theProject.startDate).format('DD/MM/YYYY')}
                        id="last-name"
                        disabled ={true}
                        autoComplete="family-name"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>

                    
                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                        Manager
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        value={`${theProject.project_managers_email}`}
                        disabled ={true}
                        autoComplete="family-name"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                 
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                        Duration
                      </label>
                      <input
                        type="text"
                        name="last-name"
                        id="last-name"
                        value={`${theProject.duration} days`}
                        disabled ={true}
                        autoComplete="family-name"
                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    </div>

                      <div>
                    <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                      Description
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="about"
                        name="about"
                        rows={5}
                        disabled={true}
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                        value={theProject.description}
                        
                      />
                    </div>
                  
                  </div>
                  </>
         
    )
}