import React, {useState} from 'react';
import { Link } from 'react-router-dom'
import {Table,Header,HeaderCell,HeaderRow,Body,Row,Cell} from '@table-library/react-table-library/table'
import {usePagination} from '@table-library/react-table-library/pagination'
import {ChevronDownIcon} from '@heroicons/react/solid'
import {selectCompany,selectProjects} from '../reactWrapper/Redux/userProfileSlice'
import { useSelector} from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
// import ClientOnlyPortal from '../Pages/ClientOnlyPortal'





 export const ProjectTable=()=>{
// const companyData = useSelector(selectCompany)
// const Data = companyData == null?[]:companyData.data[0].companyProject
const allProjects = useSelector(selectProjects)
const Data = allProjects == null?[]:allProjects.data
console.log(Data,'Data')
const toFilter = [ 'Scratch Build', 'Quick Fix', 'Maintainance']
const [dropDown, setDropDwn] = useState(false)
const [search, setSearch] = useState('')
const [filterBidder, setFilter] = useState(toFilter)

  const data = {nodes: Data.filter((item)=> item.name.includes(search) || (filterBidder.includes('Scratch Build')&& item.type === 'Scratch Build') ||
  (filterBidder.includes('Quick Fix')&& item.type === 'Quick Fix') ||
  (filterBidder.includes('Maintainance')&& item.type === 'Maintainance') 
  
)}

const handleFilter=(filter)=>{
filterBidder.includes(filter) ?
setFilter(filterBidder.filter((value)=> value === filter)) :
setFilter(filterBidder.concat(filter))
}
const handleSearch =(e)=>{
  setSearch(e.target.value)
}

  const pagination = usePagination(data, {
    state:{
      page:0,
      size:40
    }
  })
  return(
    <>
    
    <div className='text-lg font-lg text-gray-900 font-poppins'>Projects</div>
    <div className="flex sm:flex-1 sm:flex sm:items-center ">
    <div className='text-gray-800 bg-white p-1 border mr-2  font-poppins'>
    Filter By <span onClick={()=> setDropDwn(!dropDown)}><ChevronDownIcon className='w-6 h-6 text-gray-100'/></span>
  </div>
  <div>
    <input
      id=""
      type='text'
      value={search}
      onChange={handleSearch}
      className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-300 w-1/3 h-10 focus:outline-none focus:border-indigo-500"
    />
  </div>
  </div>
  <div>
    { dropDown && (
      <div  className="bg-white rounded-lg shadow w-1/3">
        <ul className= "divide-y-2 divide-gray-100">
          <li className="p-3 hover:bg-gray-300" onClick={()=>handleFilter('Scratch Build')}>Scratch Build</li>
          <li className="p-3 hover:bg-gray-300" onClick={()=>handleFilter('Maintainance')}>Maintainace</li>
          <li className="p-3 hover:bg-gray-300" onClick={()=>handleFilter('Quick Fix')}>Quick Fix</li>        
        </ul>
      </div>
    )}
  </div>


    <div className="flex flex-col mt-5">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <Table data={data} pagination={pagination} className="min-w-full divide-y divide-gray-200">
          {(tableList)=>(
            <>
            <Header className="bg-gray-50">
            <HeaderRow>
            <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              #
             </HeaderCell>
             <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Project Name
             </HeaderCell>
             <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Duration
             </HeaderCell>
             <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Owner
             </HeaderCell>
             <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Status
             </HeaderCell>
              <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Charge
              </HeaderCell>
              <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Start
              </HeaderCell>
              <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              End
              </HeaderCell>
              <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Manager
              </HeaderCell>
              <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Edit
              </HeaderCell>
              <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Bidders
              </HeaderCell>
            </HeaderRow>
        </Header>

        <Body className="bg-white divide-y divide-gray-200 ">
        {tableList.map((item,index) =>(
        <Row key={index} item={item} >
        {(tableItem)=>(
                    <>
                  
                      
                    <Cell className="px-6 py-4 whitespace-nowrap">
                   <div className="flex items-center">                     
                     <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">{index+1}</div>
               
                    </div>
                   </div>
                   </Cell>

                <Cell className="px-6 py-4 whitespace-nowrap">
                <div  className="text-sm text-gray-900">
                {tableItem.name}
                </div>
                </Cell>

                <Cell className="px-6 py-4 whitespace-nowrap">
                <div  className="text-sm text-gray-900">
                {tableItem.duration}
              </div>
                </Cell>

                <Cell  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><img className="h-10 w-10 rounded-full" src={tableItem.owner_logo} alt='companyLogo'/>                        
                
                </Cell>

                <Cell  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {tableItem.is_Ongoing}
                </Cell>

                <Cell  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {tableItem.totalCharge}
                </Cell>
                <Cell  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {tableItem.startDate}
                </Cell>

                <Cell  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {tableItem.endDate}
                </Cell>
                <Cell  className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {tableItem.project_managers_email}
                </Cell>
                
                <Cell className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                 <Link to={`/project/update/${tableItem.uuid}`}  className="text-indigo-600 hover:text-indigo-900">Edit</Link>
                 </Cell>
                <Cell className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <Link to={`/project/bidders/${tableItem.uuid}`}  className="text-indigo-600 hover:text-indigo-900">View Bidders</Link>
                 </Cell>
                </>
                  )}
               

            </Row>
            ))}
           
        </Body>
          </>
          )}
        </Table>
                
                  

                  <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
  <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
    <div>
      <p className="text-sm text-gray-700">
        Total Pages :
        <span className="font-medium">{pagination.state.getTotalPages(data.nodes)}</span>   
      </p>
    </div>
    <div>
      <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
        {pagination.state.getPages(data.nodes).map((_,index)=>(
           <button 
            aria-current="page" 
            className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            onClick={()=>pagination.fns.onSetPage(index)}
            >
            {index +1}
           </button>
        ))}        
      </nav>
    </div>
  </div>
</div>



          </div>
        </div>
      </div>
    </div>
    </>
  )
}








