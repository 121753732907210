
import { Link } from 'react-router-dom'
import {selectCompany} from '../reactWrapper/Redux/userProfileSlice'
import React from 'react';
import { useSelector} from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
// import ClientOnlyPortal from '../Pages/ClientOnlyPortal'



 export const CompanyTable =()=>{
  const companyData = useSelector(selectCompany)
  const Data = companyData == null?[]:companyData.data
  return(
      <>
    <div className='text-lg font-lg text-gray-900 font-poppins'>Company</div>
    
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
          <tr>
  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">#</th>
  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phone</th>
  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Address</th>
  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Logo</th>
  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Projects</th>
  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Gigs</th>  
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {Data.map((data,index) => (
                  <tr key={index+1}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{index+1}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">{data.name}</div>
                    </td>
                  
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{data.registerer_phone}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{data.address}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"><img className="h-10 w-10 rounded-full" src={data.companyUser.photo} alt='companyLogo'/></td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <Link to={`/company/gigs/${data.id}`}  className="text-indigo-600 hover:text-indigo-900">View</Link>
                                           
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
