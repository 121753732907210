
import {selectCompany,hydrateCompanyGig} from '../reactWrapper/Redux/userProfileSlice'
import React, {useState} from 'react';
import { useSelector,useDispatch} from 'react-redux'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import ClientOnlyPortal from '../Pages/ClientOnlyPortal'
import Modal from '../Modal'
import EasyHttp from '../helper/easyHttp'
const simpleHttp =  new EasyHttp()



 export const Gigs=()=>{
//  const dispatch = useDispatch()
 const companyData = useSelector(selectCompany)
 const [gigId,setGigId] = useState(null)
 const name = companyData == null?'':companyData.data[0].name
 const Data = companyData == null?[]:companyData.data[0].companyGigs
 const [isOpen, setIsOpen] = useState(false)

const handleAssign = async(e)=>{
e.preventDefault()
const email = e.target.pmEmail.value
const res = await simpleHttp.get(`/api/v1/admin/assignGig/${gigId}/${email}`)
if(res.status === true){
  // dispatch(hydrateCompanyGig(res.data))
  toast.info(res.message)
}
if(res.status === false){ toast.error(res.message)}

}

const openAssign = (e)=>{
  e.preventDefault()
  setGigId(e.target.getAttribute('data-id'))
  setIsOpen(true)
}

  return(
    <>
    <div>{name} Gigs</div>
      <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 w-full">
              <thead className="bg-gray-50">
                <tr className= "flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <input id="offers" name="offers" type="checkbox" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                </th> */}
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">#</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Hire</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Staff</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Stack</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Approach</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Manager</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At</th>
        
                  {/* <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Assign</span>
                  </th> */}
                
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 flex-1 sm:flex-none">
                {Data.map((data,index) => (
                  <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0" key={index+1}>
                      {/* <td className="px-6 py-4 whitespace-nowrap">
                      <input
                            id="offers"
                            name="offers"
                            type="checkbox"
                            checked={data.assign}
                            onChange={()=>handleChange(data)}
                            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                          />
                      </td> */}
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">                     
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{index+1}</div>
                         
                        </div>
                      </div>
                    </td>
                   
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {data.type}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {data.hire_type}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {data.staff_strength}
                      </span>
                    </td>
                   
                    <td>
                     {data.assigned_scrum_master == null &&(
                       <div>
                          <button
                          type='submit'
                          data-id={data.gig_id}
                          onClick={openAssign}
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >Assign</button> 
                       </div>
                     )}
                     {data.assigned_scrum_master !== null &&(
                       <div>
                          <button

                          type='#'
                          disabled
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                          >Assigned</button> 
                       </div>
                     )}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {data.stack}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {data.project_approach}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {data.assigned_scrum_master}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {data.gig_status}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                        {data.created_at}
                      </span>
                    </td>
                    
                  
                  </tr>
                  
                                 
                ))}
                
                
              </tbody>
              
            </table>
          
           
          </div>
      
        </div>
      </div>
    </div>
    
    <Modal open={isOpen} onClose={()=>{setIsOpen(false)}} title='Assign Gig' handleAction={handleAssign}>
    <div className="m-4">
   
    <label className="block text-gray-700 text-sm font-bold mb-2" >
    Scrum master 
    </label>
    <input className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
    type="text" placeholder='email' name='pmEmail'/>
    </div>
    </Modal>
    <ToastContainer
      position="top-right"
      autoClose={10000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      />
   
  
    </>
 

    
  )
}
