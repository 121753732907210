import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {ContextProvider} from './reactWrapper/Context/AppContext'
import { store } from './reactWrapper/Redux/store.js'
import { Provider } from 'react-redux'

import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import reportWebVitals from './reportWebVitals';

let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
  <ContextProvider>
  <Provider store={store}>
  {/* <PersistGate loading={null} persistor={persistor}> */}
      <App/>
  {/* </PersistGate> */}
  </Provider>
  </ContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
