
import {React ,useEffect} from 'react'
import EasyHTTP from '../../../src/helper/easyHttp'
import {usePreloaderDispatchContext,usePreloaderContext} from '../../reactWrapper/Context/AppContext'
import {useDispatch} from 'react-redux'
import {hydrateProfile,hydrateCompanyData,hydrateDevelopersData,hydrateProjectManagerData,hydratProjectData} from '../../reactWrapper/Redux/userProfileSlice'
import { useLocation,useParams } from 'react-router-dom'
import queryString from 'query-string'
const simpleHttp =  new EasyHTTP()


if(process.env.REACT_APP_ENV ==='development'){
    // var api_origin = 'http://localhost:3333'
    var api_origin = 'https://api.droomwork.io'
    var base_url = 'http://localhost:3000'
  }else{
    api_origin = 'https://api.droomwork.io'
    base_url = 'https://droomwork.io'
  }

 export const Header=({children})=>{
  const defaultPhoto = process.env.REACT_APP_DEFAULT_PHOTO
  const location = useLocation();
  const value=queryString.parse(location.search)
  const token=value.enter
  if(token && token.startsWith('ey')){var userToken = token}
  
  const dispatch = useDispatch()
  const preloaderContext = usePreloaderContext()
  const preloaderDispatchContext = usePreloaderDispatchContext()

  const Bootapp =  async ()=>{
    useEffect(() => {
     if(!userToken){
      console.log('Not supposed to enter here')
         // u can do an api call to check token alive here
           var droomworkUserData = JSON.parse(localStorage.getItem("persist:root"));
           var droomworkUserToken = JSON.parse(localStorage.getItem("user-token"));
           if(droomworkUserData && droomworkUserToken){
             switch (preloaderContext.preloaderdisplay){
                 case true:
                   return preloaderDispatchContext({
                       type: 'PRELOADER_DISPLAY',
                       payload: false
                     });
                     
                 default:
                  return preloaderDispatchContext({
                       type: 'PRELOADER_DISPLAY',
                       payload: true
                     });
                     
             }
             
         }else{
           console.log('redirect1')
           window.location.href = `${base_url}/getStarted`
          
         }
         
     }
    }, [])
  
    useEffect(() => {
    async function fetchData(){
      try {
        if(userToken){
        localStorage.setItem('user-token', JSON.stringify(userToken))
        console.log('user-token is just set')
         const data = {userToken:userToken}
         const res = await simpleHttp.postNoAuth('/api/v1/all/getUserProfile',data)
         console.log(res,'res')
        if(res.status === true){
          console.log('Just hydrateProfile')
          dispatch(hydrateProfile(res.data))
      
                }
        if(res.status === false){
            console.log('Redirect')
            localStorage.removeItem("user-token")
            localStorage.removeItem("persist:root")
            window.location.href = `${base_url}/getStarted`
            console.log('redirect2')
        }}     
        
     } catch (error) {
      if(typeof window!=="undefined"){
        localStorage.removeItem("user-token")
        localStorage.removeItem("persist:root")
        window.location.href = `${base_url}/getStarted`
        console.log('redirect3')
      }
     }
    }
    fetchData()
    }, [])
  
  
   }
  
   const HydrateCompany = ()=>{
    useEffect(() => {
    async function fetchData() {
      var Token = JSON.parse(localStorage.getItem("user-token"));
      const re = await fetch(`${api_origin}/api/v1/admin/getCompanyList`,{
      headers:{
          'Accept':'application/json',
          'Authorization': `Bearer ${Token}`,
          'Content-type':'application/json',
          'Access-Control-Allow-Origin':'*'
      }
      })
      const resCompany = await re.json()
      console.log(resCompany,'rescompany')
      if(resCompany.status === true){dispatch(hydrateCompanyData(resCompany.data))}
      if(resCompany.status === false){console.log('company error',resCompany)} 
      }
      fetchData();

    },[])

  }
 
  const HydrateProjectManagerData = ()=>{
    useEffect(() => {
    async function fetchData() {
      var Token = JSON.parse(localStorage.getItem("user-token"));
      const re = await fetch(`${api_origin}/api/v1/admin/getProjectManagersList`,{
      headers:{
          'Accept':'application/json',
          'Authorization': `Bearer ${Token}`,
          'Content-type':'application/json',
          'Access-Control-Allow-Origin':'*'
      }
      })
      const resProjectMgr = await re.json()
      if(resProjectMgr.status === true){dispatch(hydrateProjectManagerData(resProjectMgr.data))}
      if(resProjectMgr.status === false){console.log('projectData error',resProjectMgr)} 
      }
      fetchData();

    },[])

  }

  const HydrateDevelopersData = ()=>{
    useEffect(() => {
    async function fetchData() {
      var Token = JSON.parse(localStorage.getItem("user-token"));
      const re = await fetch(`${api_origin}/api/v1/admin/getDevelopersList`,{
      headers:{
          'Accept':'application/json',
          'Authorization': `Bearer ${Token}`,
          'Content-type':'application/json',
          'Access-Control-Allow-Origin':'*'
      }
      })
      const resDevelopers = await re.json()
      console.log(resDevelopers,'Nanny')
      if(resDevelopers.status === true){dispatch(hydrateDevelopersData(resDevelopers.data))}
      if(resDevelopers.status === false){console.log('developers list error',resDevelopers)}
      }
      fetchData()

    },[])

  }
  const AllProjectsData = ()=>{
    useEffect(() => {
    async function fetchData() {
      var Token = JSON.parse(localStorage.getItem("user-token"));
      const re = await fetch(`${api_origin}/api/v1/admin/getAllProjects`,{
      headers:{
          'Accept':'application/json',
          'Authorization': `Bearer ${Token}`,
          'Content-type':'application/json',
          'Access-Control-Allow-Origin':'*'
      }
      })
      const resProjects = await re.json()
      if(resProjects.status === true){dispatch(hydratProjectData(resProjects.data))}
      if(resProjects.status === false){console.log('developers list error',resProjects)}
      }
      fetchData()

    },[])

  }


  const BootAll = ()=>{
          Bootapp()
          HydrateCompany()
          HydrateProjectManagerData()
          HydrateDevelopersData()
          AllProjectsData()
          useEffect(() => {
            setTimeout(() => {
              switch (preloaderContext.preloaderdisplay){
                case true:
                window.history.replaceState(null, "", window.location.href.split("?")[0])
                  return preloaderDispatchContext({
                      type: 'PRELOADER_DISPLAY',
                      payload: false
                    });
                    
                default:
                  return preloaderDispatchContext({
                      type: 'PRELOADER_DISPLAY',
                      payload: true
                    });
                    
                      } 
            }, 1500)
          }, [])
  
  
        }
  BootAll()

  return(
    <>
  {preloaderContext.preloaderdisplay ? (
    <div className="w-full h-screen left-0 z-999999999 bg-gray-900 fixed ">
    <div className="mt-80 animate-bounce">
    <img className="w-auto h-auto left-1/2 absolute" src='/preloader.gif' alt='preloader'/>
    </div>
    </div>
   ) :   <main className="main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in">
       <header className="header bg-white shadow py-4 px-4">
         <div className="header-content flex items-center flex-row">
           <form action="#">
             <div className="hidden md:flex relative">
               <div
                 className="inline-flex items-center justify-center absolute left-0 top-0 h-full w-10 text-gray-400"
               >
                 <svg
                   className="h-6 w-6"
                   fill="none"
                   stroke-linecap="round"
                   stroke-linejoin="round"
                   stroke-width="2"
                   viewBox="0 0 24 24"
                   stroke="currentColor"
                 >
                   <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                 </svg>
               </div>
   
               <input
                 id="search"
                 type="text"
                 name="search"
                 className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-300 w-full h-10 focus:outline-none focus:border-indigo-400"
                 placeholder="Search..."
               />
             </div>
             <div className="flex md:hidden">
               <a href="/" className="flex items-center justify-center h-10 w-10 border-transparent">
                 <svg
                   className="h-6 w-6 text-gray-500"
                   fill="none"
                   stroke-linecap="round"
                   stroke-linejoin="round"
                   stroke-width="2"
                   viewBox="0 0 24 24"
                   stroke="currentColor"
                 >
                   <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                 </svg>
               </a>
             </div>
           </form>
           <div className="flex ml-auto">
             <a href='/' className="flex flex-row items-center">
               <img
                 src={defaultPhoto}
                 alt =""
                 className="h-10 w-10 bg-gray-200 border rounded-full"
               />
               <span className="flex flex-col ml-2">
                 <span className="truncate w-20 font-semibold tracking-wide leading-none">John Doe</span>
                 <span className="truncate w-20 text-gray-500 text-xs leading-none mt-1">Admin</span>
               </span>
             </a>
           </div>
         </div>
       </header>
       {children}
       </main>}
       
      
   
     
        
           
    </>
  );
}


