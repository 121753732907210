import React,{useState,useEffect} from 'react'
import {Table,Header,HeaderCell,HeaderRow,Body,Row,Cell} from '@table-library/react-table-library/table'
import { useRowSelect, HeaderCellSelect, CellSelect, SelectClickTypes} from  '@table-library/react-table-library/select'
import {useAddTeamContext,useAddTeamDispatchContext} from '../reactWrapper/Context/AppContext'
import {selectProjectBidders,hydratProjectBiddersData} from '../reactWrapper/Redux/userProfileSlice'
import { useSelector,useDispatch} from 'react-redux'
import {usePagination} from '@table-library/react-table-library/pagination'
import { useSort, HeaderCellSort,} from '@table-library/react-table-library/sort'
import {ChevronDownIcon} from '@heroicons/react/solid'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import ClientOnlyPortal from '../Pages/ClientOnlyPortal'
import EasyHttp from '../helper/easyHttp'
const simpleHttp =  new EasyHttp()


 
  


 export const Bidding=()=>{
const dispatch = useDispatch()
const bidderDetails = useSelector(selectProjectBidders)
const addTeamContext = useAddTeamContext()
const addTeamDispatchContext = useAddTeamDispatchContext()
const biddersData = bidderDetails== null?[]:bidderDetails.data

useEffect(() => {
  async function fetchData() {
  const uuid = window.location.pathname.slice(17)
  const res = await simpleHttp.get(`/api/v1/admin/biddingDetails/${uuid}`)
  if(res.status === true){
    dispatch(hydratProjectBiddersData(res.data))
  }
  if(res.status === false){ toast.error(res.message)}
  }
  fetchData()
  },[dispatch])
   
    const toFilter = [ 'Frontend', 'Backend', 'Technical Writing', 'UI/UX']
    const [dropDown, setDropDwn] = useState(false)
    const [filterBidder, setFilter] = useState(toFilter)

    const handleFilter=(filter)=>{
       filterBidder.includes(filter) ?
        setFilter(filterBidder.filter((value)=> value === filter)) :
        setFilter(filterBidder.concat(filter))

        console.log(filter)
    }

//     const data = {nodes: Bidders.filter((item)=> (filterBidder.includes('Frontend')&& item.specialization === 'Frontend') ||
//     (filterBidder.includes('Backend')&& item.specialization === 'Backend') ||
//     (filterBidder.includes('Technical Writing')&& item.specialization === 'Technical Writing') ||
//     (filterBidder.includes('UI/UX')&& item.specialization === 'UI/UX')
    
// )}

const data = {nodes:biddersData}
const onSelectChange= (action, state)=>{
     
 console.log(state.ids,'myman')
 return addTeamDispatchContext({
  type: 'ADDED_TEAM_MEMBER',
  payload:state.ids
});
       }

    const select = useRowSelect(
      data,
      {
        onChange: onSelectChange,
      },
      {
        clickType: SelectClickTypes.ButtonClick,
      }
    );
    const sort = useSort(data,
       {
         sortIcon: {
           iconDefault: null,
           iconUp: null,
           iconDown: null,
         }
       }
       
    )

    const pagination = usePagination(data, {
      state:{
        page:0,
        size:3000000
      },
 
    })

    const handleOnClick = async(e)=>{
      e.preventDefault()
      const theIds =  addTeamContext.addedTeamMemberId
      let theBidderDetails = bidderDetails.data.find(single => single.id === theIds[0])
      console.log(theBidderDetails,'theBidderDetails')
      const uid = theBidderDetails.user_id
      const pid = theBidderDetails.project_id
      const specialization = theBidderDetails.specialization
      const res = await simpleHttp.get(`/api/v1/admin/addToTeam/${pid}/${specialization}/${uid}`)
      if(res.status === true){ toast.info(res.message)}
      if(res.status === false){ toast.error(res.message)}

      
    }
    
      
     
   
  return(
    
        <>
      <div className='text-lg  text-gray-900 font-poppins'>Bidders</div>
      <div className='flex sm:flex-1 sm:flex sm:items-center '>
        <div className='text-gray-900 bg-white mr-2  p-2 border font-poppins'>
        Filter By 
        <ChevronDownIcon onClick={()=> setDropDwn(!dropDown)} className='w-6 h-6 text-gray-100'/>  
        
        </div>
        <div>
        {console.log(select.state,'view')}
         {
       select.state.ids.length !== 0 && (
          <button
          type='submit'
          onClick={handleOnClick}
          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-900 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
           >Add To Team
       </button>
        )
        }
           
       </div>
      </div>
      <div>
        { dropDown && (
          <div className="bg-white rounded-lg shadow w-1/4">
            <ul className='divide-y-2 divide-gray-100'>
              <li className="p-3 hover:bg-gray-300" onClick={()=>handleFilter('Frontend')}>FrontEnd</li>
              <li className="p-3 hover:bg-gray-300" onClick={()=>handleFilter('Backend')}>BackEnd</li>
              <li className="p-3 hover:bg-gray-300" onClick={()=>handleFilter('Technical Writing')}>Technical Writers</li>
              <li className="p-3 hover:bg-gray-300" onClick={()=>handleFilter('UI/UX')}>UI/UX</li>
            </ul>
          </div>
        )}
      </div>

    
    

    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block  sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <Table data={data}  select={select} sort={sort} pagination={pagination} className=" divide-y divide-gray-200"> 
                {(tableList)=>(
                   <>
                     <Header className="bg-gray-50">
                     <HeaderRow>
                       <HeaderCellSelect/>
                       <HeaderCell>#</HeaderCell>
                         <HeaderCell>Name</HeaderCell>
                         <HeaderCellSort sortKey='SPECIALTY' sortFn={(array)=> array.sort((a,b)=> a.specialization - b.specialization)}>Specialty</HeaderCellSort><HeaderCellSort sortKey='BIDDED' sortFn={(array)=> array.sort((a,b)=> a.bidded_amt - b.bidded_amt)}>
                             Price
                         </HeaderCellSort>
                         <HeaderCellSort sortKey='RATE' sortFn = {(array)=> array.sort((a,b)=> a.rate - b.rate)}>Experience
                         </HeaderCellSort>
                         <HeaderCell>Photo</HeaderCell>
                         <HeaderCell>Email</HeaderCell>
                     </HeaderRow>
                 </Header>

                 <Body className="bg-white divide-y divide-gray-200 ">
                 
                     {tableList.map((item,index) =>(
                         <Row key={item.id} item={item} >
                           {(tableItem)=>(
                             <>
                        <CellSelect className="px-4 py-4 whitespace-nowrap" item={tableItem} />
                         <Cell className="px-6 py-4 whitespace-nowrap">
                         <div className="flex-shrink-0 h-10 w-10">
                         {index+1}
                         </div>
                         </Cell>
                               
                             <Cell className="px-6 py-4 whitespace-nowrap">
                            <div className="flex items-center">                     
                              <div className="ml-4">
                               <div className="text-sm font-medium text-gray-900">{tableItem.negotiatedDetailUser.userName}</div>
                        
                             </div>
                            </div>
                            </Cell>

                         <Cell className="px-6 py-4 whitespace-nowrap">
                         <div className="flex-shrink-0 h-10 w-10">
                         {tableItem.specialization}
                         </div>
                         </Cell>

                         <Cell className="px-6 py-4 whitespace-nowrap">
                         <div className="flex-shrink-0 h-10 w-10">
                         {tableItem.developersPrice}
                       </div>
                         </Cell>

                         <Cell className="px-6 py-4 whitespace-nowrap">
                         <div className="flex-shrink-0 h-10 w-10">
                         {tableItem.experience}
                       </div>
                         </Cell>
                         <Cell className="px-6 py-4 whitespace-nowrap">
                         <div className="flex-shrink-0 h-10 w-10">
                          <img className="h-10 w-10 rounded-full" src={tableItem.negotiatedDetailUser.photo} alt="" />
                       </div>
                         </Cell>
                         <Cell className="px-6 py-4 whitespace-nowrap">
                         <div className="flex-shrink-0 h-10 w-10">
                         {tableItem.negotiatedDetailUser.email}
                       </div>
                         </Cell>
                         </>
                           )}
                        

                     </Row>
                     ))}
                    
                 </Body>
                 </>
                )}
                
              </Table>
              <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
  <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
    <div>
      <p className="text-sm text-gray-700">
        Total Pages :
        <span className="font-medium">{pagination.state.getTotalPages(data.nodes)}</span>   
      </p>
    </div>
    <div>
      <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
        {pagination.state.getPages(data.nodes).map((_,index)=>(
           <button 
            aria-current="page" 
            className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            onClick={()=>pagination.fns.onSetPage(index)}
            >
            {index +1}
           </button>
        ))}        
      </nav>
    </div>
  </div>
</div>
         
          </div>      
        </div>
      </div>
    </div>
    <ToastContainer
      position="top-right"
      autoClose={10000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      />
    </>
  )
}














































































































































































































// <table className="min-w-full divide-y divide-gray-200 w-full">
// <thead className="bg-gray-50">
//   <tr className= "flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
//       <th
//         scope="col"
//         className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//       >
//       <input
//               id="offers"
//               name="offers"
              
//               type="checkbox"
//               className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
//             />
//       </th>
//     <th
//       scope="col"
//       className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//     >
//       Project Manager
//     </th>
    
//     <th
//       scope="col"
//       className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//     >
//       Status
//     </th>
//     <th
//       scope="col"
//       className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
//     >
//       Image
//     </th>
//     <th scope="col" className="relative px-6 py-3">
//       <span className="sr-only">Assign</span>
//     </th>
  
//   </tr>
// </thead>
// <tbody className="bg-white divide-y divide-gray-200 flex-1 sm:flex-none">
//   {prjmanagers.map((manager) => (
//     <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
//         <td className="px-6 py-4 whitespace-nowrap">
//         <input
//               id="offers"
//               name="offers"
//               type="checkbox"
//               checked={manager.assign}
//               onChange={()=>handleChange(manager)}
//               className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
//             />
//         </td>
//       <td className="px-6 py-4 whitespace-nowrap">
//         <div className="flex items-center">                     
//           <div className="ml-4">
//             <div className="text-sm font-medium text-gray-900">{manager.name}</div>
           
//           </div>
//         </div>
//       </td>
     
//       <td className="px-6 py-4 whitespace-nowrap">
//         <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
//           {manager.status}
//         </span>
//       </td>
//       <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
//       <div className="flex-shrink-0 h-10 w-10">
//             <img className="h-10 w-10 rounded-full" src={manager.image} alt="" />
//           </div>
//       </td>
//       <td>
//        {manager.assign &&(
//          <div>
//             <button
//             type='submit'
//             className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
//             >Assign</button> 
//          </div>
//        )}
//       </td>
    
//     </tr>
    
                   
//   ))}
  
  
// </tbody>

// </table>