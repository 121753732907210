import React from 'react'
import {selectProjectManager} from '../reactWrapper/Redux/userProfileSlice'
import { useSelector} from 'react-redux'
import 'react-toastify/dist/ReactToastify.css'
import Moment from 'moment'



 

 export const PMTable=()=>{
  const defaultPhoto = process.env.REACT_APP_DEFAULT_PHOTO
  const projectManagerData = useSelector(selectProjectManager)
  const Data =  projectManagerData == null?[]: projectManagerData.data
  console.log(Data,'Data')
  return(
      <>
    <div className='text-lg font-lg text-gray-900 font-poppins'>Project Manager</div>
    
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                #
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Name
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Phone
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Level
                  </th>
                  <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Managed Projects
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Created At
                </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {Data.map((person,index) => (
                  <tr key={person.email}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{index+1}</td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img className="h-10 w-10 rounded-full" src={person.photo==null?defaultPhoto:person.photo} alt="user" />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{person.firstName == null?'':person.firstName} {person.lastName==null?'':person.lastName}</div>
                          <div className="text-sm text-gray-500">{person.userProjectManagerDetail.previousWorkplace == null?'':person.userProjectManagerDetail.previousWorkplace}</div>
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">{person.phone==null?'':person.phone}</div>
                      <div className="text-sm text-gray-500">{person.email == null?'':person.email}</div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      {person.is_verified === 1?'Verified':'Not Verified'}
                      </span>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person.userProjectManagerDetail.level==null?'':person.userProjectManagerDetail.level}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person.userProjectManagerDetail.noOfProjectsManaged==null?'':person.userProjectManagerDetail.noOfProjectsManaged}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{Moment(person.created_at).format('DD/MM/YYYY')}</td>
                    
                  
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
