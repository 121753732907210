import React from 'react'
import { PMTable } from '../components/ProjectManagersTable'




export const ProjectManagers =()=>{
    return(
        <div>
     
        <PMTable/>
        </div>
    )
}