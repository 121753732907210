import React from 'react'
import { Wrapper } from '../components/Projects/EditWrapper'



export const EditProject =()=>{
    return(
        <div>
           <Wrapper/>
        </div>
    )
}