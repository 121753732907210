import React,{useState} from 'react'
import {Table,Header,HeaderCell,HeaderRow,Body,Row,Cell} from '@table-library/react-table-library/table'
import {usePagination} from '@table-library/react-table-library/pagination'
import {ChevronDownIcon} from '@heroicons/react/solid'
import {selectDevelopers} from '../reactWrapper/Redux/userProfileSlice'
import { useSelector} from 'react-redux'
import Moment from 'moment'




 export const DevTable=()=>{

  const developersData = useSelector(selectDevelopers)
  const Data =  developersData == null?[]:developersData.data
  console.log(Data,'Data')
  const toFilter = [ 'Frontend', 'Backend', 'Full Stack', 'react', 'vue', 'ruby on rails',]

  const [dropDown, setDropDwn] = useState(false)
  const [search, setSearch] = useState('')
  const [filterBidder, setFilter] = useState(toFilter)

//   const data = {nodes: dev.filter((item)=>item.name.includes(search) || (filterBidder.includes('Frontend')&& item.skill === 'Frontend') ||
//   (filterBidder.includes('Backend')&& item.skill === 'Backend') ||
//   (filterBidder.includes('Full Stack')&& item.skill === 'Full Stack') ||
//   (filterBidder.includes('react')&& item.frameworks.includes('react')) ||
//   (filterBidder.includes('vue')&& item.frameworks.includes('vue')) ||
//   (filterBidder.includes('ruby on rails')&& item.frameworks.includes('ruby on rails')) 
  
// )}

const data = {nodes: Data}
  
   const pagination = usePagination(data,{
     state:{
       page:0,
       size:50
     }
   })

const handleFilter=(filter)=>{
filterBidder.includes(filter) ?
setFilter(filterBidder.filter((value)=> value !== filter)) :
setFilter(filterBidder.concat(filter))
}

const handleSearch =(e)=>{
   setSearch(e.target.value)
}
  return(
    <>
    
      <div className='text-sm font-lg text-gray-900 font-poppins'>Developers</div>
    <div className="flex sm:flex-1 sm:flex sm:items-center ">
    <div className='text-gray-900 bg-white mr-2 p-2 border'>
    Filter By <span onClick={()=> setDropDwn(!dropDown)}><ChevronDownIcon className='w-6 h-6 text-gray-100'/></span>
  </div>

  <div>
    <input
      id=""
      type='text'
      value={search}
      onChange={handleSearch}
      placeholder='Search Developers'
      className="text-sm sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-gray-300 w-1/4 h-10 focus:outline-none focus:border-indigo-500"
    />
  </div>
  </div>
  <div>
    { dropDown && (
      <div className="w-1/3 bg-white rounded-lg shadow">
        <ul className='divide-y-2 divide-gray-100'>
          <li className='p-3 hover:bg-gray-300'  onClick={()=>handleFilter('Frontend')}>FrontEnd</li>
          <li className='p-3 hover:bg-gray-300'  onClick={()=>handleFilter('Backend')}>BackEnd</li>
          <li className='p-3 hover:bg-gray-300'  onClick={()=>handleFilter('Full Stack')}>Technical Writers</li>
          <li className='p-3 hover:bg-gray-300'  onClick={()=>handleFilter('ruby on rails')}>Ruby on Rails</li>
          <li className='p-3 hover:bg-gray-300'  onClick={()=>handleFilter('react')}>React</li>
          <li className='p-3 hover:bg-gray-300'  onClick={()=>handleFilter('vue')}>VueJs</li>

        </ul>
      </div>
    )}
  </div>

    <div className="flex flex-col mt-5">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block  sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <Table data={data} pagination={pagination} className="min-w-full divide-y divide-gray-200">
              {(tableList)=>(
                <>
                <Header className="bg-gray-50">
                <HeaderRow>
                <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">#</HeaderCell>
                  <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">User</HeaderCell>
                  <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Phone</HeaderCell>
               
                  <HeaderCell 
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Specialization</HeaderCell>
             
                  <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Stack</HeaderCell>
                  <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Level</HeaderCell>
                  <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Experience</HeaderCell>
                  <HeaderCell scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At</HeaderCell>
                </HeaderRow>
              </Header>

              <Body className="bg-white divide-y divide-gray-200">
              {tableList.map((item,index) => (
              <Row key={item.id} item={item}>
                {(tableItem) => (
                  <>
                   <Cell className="px-6 py-2 whitespace-nowrap"> 
                   <div className="text-sm text-gray-900"> {index+1}</div>                   
                  
                    </Cell>
                  <Cell className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                        <div className="flex-shrink-0 h-10 w-10">
                          <img className="h-10 w-10 rounded-full" src={tableItem.photo} alt="" />
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">{tableItem.userName}</div>
                          <div className="text-sm text-gray-500">{tableItem.email}</div>
                        </div>
                      </div>
                  </Cell>
                    
                  
                    <Cell className="px-6 py-4 whitespace-nowrap">{tableItem.phone}</Cell>
                    <Cell className="px-6 py-4 whitespace-nowrap">                    
                    <div className="text-sm text-gray-900">{tableItem.userWorkDetail.specialization}</div>
                    </Cell>
                    <Cell className="px-6 py-4 whitespace-nowrap">                    
                    <div className="text-sm text-gray-900">{tableItem.userWorkDetail.stack}</div>
                    </Cell>
                    <Cell className="px-6 py-4 whitespace-nowrap">                    
                    <div className="text-sm text-gray-900">{tableItem.userWorkDetail.level}</div>
                    </Cell>
                    <Cell className="px-6 py-4 whitespace-nowrap"> 
                    <div className="text-sm text-gray-900">{tableItem.userWorkDetail.total_experience}</div>
                    </Cell>
                    <Cell className="px-6 py-4 whitespace-nowrap"> 
                    <div className="text-sm text-gray-900">{Moment(tableItem.created_at).format('DD/MM/YYYY')}</div>
                    </Cell>
                    
                    

                  </>
                )}
              </Row>
            ))}
              </Body>
              </>
              )}

            </Table>


<div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
  <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
    <div>
      <p className="text-sm text-gray-700">
        Total Pages :
        <span className="font-medium">{pagination.state.getTotalPages(data.nodes)}</span>   
      </p>
    </div>
    <div>
      <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
        {pagination.state.getPages(data.nodes).map((_,index)=>(
           <button 
            aria-current="page" 
            className="z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            onClick={()=>pagination.fns.onSetPage(index)}
            >
            {index +1}
           </button>
        ))}        
      </nav>
    </div>
  </div>
</div>

          </div>
        </div>
      </div>
    </div>
    </>
  )
}




