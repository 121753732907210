import React from 'react'
import { Gigs } from '../components/GigTable'





export const companyGigList =()=>{
    return(
        <div>          
           <Gigs/>
        </div>
    )
}