import { useState, useEffect } from 'react';
import NewEditor from './newEditor';
import { useHistory } from "react-router-dom";
import { useBlogPostMutation, useGetCategoryQuery, useGetAuthorQuery } from '../../reactWrapper/Redux/apiSlice';
import { toast } from "react-toastify";

const FirstPage =() => {


    const [ blogPost, {isLoading: isPosting, isSuccess, error }] = useBlogPostMutation();


    const { data: category, isLoading: loadingCat, isError: errCat, error: errCatMsg } = useGetCategoryQuery();
    console.log('category data', category);
    const { data: authors, isLoading: loadingAut, isError: errAut, error: errAutMsg } = useGetAuthorQuery();
    

    const history = useHistory();

    const [initialValue, setInitialValue] = useState('<p> Type blog content here </p>');

    const [title, setTitle] = useState('');
    const [category_id, setCategory_Id] = useState();
    const [contentEditor, setContentEditor] = useState(initialValue ?? '');
    const [featured_image_link, setFeatured_Image_Link] = useState(null);
    const [featured_image_links, setFeatured_Image_Links] = useState(null);
    const [authors_name, setAuthors_Name] = useState('');
    const [meta_description, setMeta_Description] = useState('');
    const [key_phrase, setKey_Phrase] = useState('');


    useEffect(() => {
        console.log('data', category)
        if(errCatMsg) {
            toast.error("Couldn't fetch category data")
        }
    }, [category, errCatMsg])

    useEffect(() => {
        console.log('data', authors)
        if(errAutMsg) {
            toast.error("Couldn't fetch author's data")
        }
    }, [authors, errAutMsg])

    useEffect(() => {
        if(error) {
            toast.error(error.data.message)
            console.log("error part", error)
        }
    }, [error])

    const handleSubmit = async (e) => {
        e.preventDefault();
        var formdata = new FormData();

        formdata.append("title", title);
        formdata.append("content", contentEditor);
        formdata.append("authors_name", authors_name);
        formdata.append("featured_image_link", featured_image_link);
        formdata.append("category_id", category_id);
        formdata.append("meta_description", meta_description);
        formdata.append("key_phrase", key_phrase);

        // await blogPost({ title, featured_image_link, content, blogPostCategories, authors_name, meta_description, key_phrase })

        try{
            await blogPost(formdata)
            setTitle('')
            setCategory_Id()
            setAuthors_Name('')
            setMeta_Description('')
            setKey_Phrase('')
            // console.log("this is d form data", ...formdata, contentEditor)
            // console.log("this is the content", contentEditor)
        } catch (error) {
            toast.error(error.data.message)
            console.error('Failed to save the post: ', error)
        }

        // await blogPost(formdata)
        // setTitle('')
        // setCategory_Id()
        // setAuthors_Name('')
        // setMeta_Description('')
        // setKey_Phrase('')
        // history.push('/mypost')
    };

    useEffect(() => {
        if(isSuccess) {
            toast.success("Blog post created sucessfully")
            history.push('/mypost')
        }
    })

    // const [message, setMessage] = useState(true)

    // const handleClick = (e) =>{
    //     e.preventDefault();
    //     setMessage(!message)
    // }

    const handleChange = (event) => {
        // if (event.target.files && event.target.files[0]) {
        setFeatured_Image_Links(URL.createObjectURL(event.target.files[0]));
        // console.log('this is the full image', featured_image_link)
        setFeatured_Image_Link(event.target.files[0])
        console.log('this is the full image', featured_image_link)
        // }
    };

    // const getId = (e) => {
    //     // const index = e.target.selectedIndex;
    //     // const optionElement = e.target.childNodes[index];
    //     // const optionElementId = optionElement.getAttribute('id');
    //     // console.log(optionElementId);

    //     const form = e.target;
    //     const select = form.elements.namedItem('category');
    //     const id = select.children[select.selectedIndex].id;
    //     console.log(id);
    // }
    
    return (
        <>
            <form onSubmit={handleSubmit}>
            <div className="py-12">
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    <div className="bg-white overflow-hidden shadow-sm sm:rounded-lg">
                        <div className="p-6 bg-white border-b border-gray-200">
                           

                            <div className="mb-4">
                                <label class="text-xl text-gray-600">Blog Image <span className="text-red-700">Image size in Kb</span><span class="text-red-500">*</span></label>
                                <br/>
                                <input type="file" className="border-2 border-gray-300 p-2" onChange={(e) => handleChange(e)}  />
                                {/* {message && 
                                    <div className="relative rounded-xl shadow-lg transition duration-500 ease-in-out bg-blue-600 hover:bg-red-600 transform hover:-translate-y-1 hover:scale-110">
                                        <span>
                                            Insert blog image
                                        </span>
                                    </div>
                                } */}
                                {featured_image_links &&
                                    <div className="h-60">
                                        <img src={featured_image_links} alt="preview view" className="object-cover w-full h-full"/>
                                    </div>
                                }
                            </div>                          

                            <div className="mb-4">
                                <label class="text-xl text-gray-600">Title <span class="text-red-500">*</span></label>
                                <br/>
                                <input type="text" className="border-2 border-gray-300 p-2 w-full" name="title" id="title" value={title} onChange={(e)=> setTitle(e.target.value)} required />
                            </div>

                            <div className="mb-4">
                                <label class="text-xl text-gray-600">key Phrases <span class="text-red-500">*</span></label>
                                <br/>
                                <input type="text" className="border-2 border-gray-300 p-2 w-full" name="key_phrase" id="key_phrase" value={key_phrase} onChange={(e)=> setKey_Phrase(e.target.value)} required />
                            </div>

                            <div className="mb-4">
                                <label class="text-xl text-gray-600">Choose Category <span class="text-red-500">*</span></label>
                                <select 
                                value={category_id} 
                                onChange={(e) => setCategory_Id(e.target.value)}
                                className="border-2 border-gray-300 border-r p-2" name="action">
                                    <option>Select category</option>
                                    {category && category.map((item) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                        )                                      
                                    )}
                                    {errCat && <div> {errCatMsg.toString()} </div>}
                                    
                                </select>
                            </div>

                            <div className="mb-4">
                                <label class="text-xl text-gray-600">Select Author <span class="text-red-500">*</span></label>
                                <select 
                                 value={authors_name} 
                                 onChange={(e)=> setAuthors_Name(e.target.value)}
                                 className="border-2 border-gray-300 border-r p-2" name="action">
                                    {loadingAut && <div> Loading </div>}
                                    <option>Select Author</option>
                                    {authors && authors.map((item) =>(
                                        
                                        <option key={item.id}>
                                            {item.name}
                                        </option>                                                            
                                    ))}
                                    {errAut && <div> {errAutMsg.toString()} </div>}
                                </select>
                            </div>

                            {/* <div className="mb-4">
                                <label className="text-xl text-gray-600">Time for read</label>
                                <br/>
                                <input type="text" className="border-2 border-gray-300 p-2 w-full" name="description" id="description" placeholder="(Optional)"/>
                            </div>

                            <div className="mb-4">
                                <label className="text-xl text-gray-600">Date Published</label>
                                <br/>
                                <input type="date" className="border-2 border-gray-300 p-2 w-full"/>
                            </div> */}

                            {/* <div className="mb-4">
                                <label className="text-xl text-gray-600">Description</label>
                                <br/>
                                <input type="text" className="border-2 border-gray-300 p-2 w-full" name="description" id="description" placeholder="(Optional)"/>
                            </div> */}

                            <div className="mb-8">
                                <label className="text-xl text-gray-600">Meta Description <span class="text-red-500">*</span></label>
                                <br/>
                                <textarea name="content" className="border-2 border-gray-500 w-full px-3 py-5 box-border text-base" value={meta_description} onChange={(e)=> setMeta_Description(e.target.value)} required />
                                
                            </div>
                          
                            <NewEditor contentEditor={contentEditor} setContentEditor={setContentEditor} initialValue={initialValue} setInitialValue={setInitialValue} />

                            <div className="flex p-1">
                                <select className="border-2 border-gray-300 border-r p-2" name="action">
                                    <option>Save and Publish</option>
                                    <option>Save Draft</option>
                                </select>
                                {isPosting
                                    ? <button className="p-3 bg-blue-500 text-white hover:bg-blue-400" >Submiting...</button>
                                    : <button className="p-3 bg-blue-500 text-white hover:bg-blue-400" >Submit</button> 
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </form>            
        </>
        
    )
}

export default FirstPage;


