import React,{useState} from 'react'
import {Link} from 'react-router-dom'
import {ChevronDownIcon} from '@heroicons/react/solid'
if(process.env.REACT_APP_ENV  ==='development'){var base_url = 'http://localhost:3000'}else{base_url = 'https://droomwork.io'}

export const SideBar=()=>{

const handleLogout = (e)=>{
e.preventDefault()
localStorage.removeItem("user-token")
localStorage.removeItem("persist:root")
window.location.href = `${base_url}/getStarted`

}

 const [open, setOpen] = useState(false)

 const toggling = () => {
  setOpen(true)
 }

return(  
<aside
className="sidebar w-45 md:shadow transform -translate-x-full md:translate-x-0 transition-transform duration-150 ease-in bg-gray-900" 
>
<div className="sidebar-header flex items-center justify-center py-4">
  <div className="inline-flex">
    <a href="/" className="inline-flex flex-row items-center">
      <span className="leading-10 text-gray-100 text-2xl font-poppins font-bold ml-1 uppercase">Droomwork</span>
    </a>
  </div>
</div>
<div className="sidebar-content px-4 py-6">
  <ul className="flex flex-col w-full">
    
    <li className="my-px">
      <a href="/"     
        className="flex flex-row items-center h-10 px-3 font-poppins  rounded-lg text-gray-100 hover:bg-yellow-500 hover:text-gray-700"
      >
         <span className="flex items-center justify-center text-lg text-gray-400">
          <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-5 w-5 text-gray-100"
          >
            <path
              d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
            />
          </svg>
        </span>
        <Link to='/projects'><span className="ml-3">Projects</span></Link>
        <ChevronDownIcon className='w-6 h-6 text-gray-100'/>
      </a>
      
    </li>
    <li className="my-px">
      <a href="/"
        className="flex flex-row items-center h-10 px-3 font-poppins  rounded-lg text-gray-100 hover:bg-yellow-500 hover:text-gray-700"
      >
        <span className="flex items-center justify-center text-lg text-gray-400">
          <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-5 w-5 text-gray-100"
          >
            <path
              d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
            />
          </svg>
        </span>
        <Link to='/projectmanagers'><span className="ml-3">Project Manager</span></Link>
        <ChevronDownIcon className='w-6 h-6 text-gray-100'/>
      </a>
      
    </li>
    <li className="my-px">
      <a
        href="/"
        className="flex flex-row items-center h-10 px-3 font-poppins  rounded-lg text-gray-100 hover:bg-yellow-500 hover:text-gray-700"
      >
        <span className="flex items-center justify-center text-lg text-gray-400">
          <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-5 w-5 text-gray-100"
          >
            <path
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
            />
          </svg>
        </span>
        <Link to ='/developers'><span className="ml-3">Developers</span></Link>
        <ChevronDownIcon className='w-6 h-6 text-gray-100'/>
      </a>
      
    </li>
    <li className="my-px">
      <a
        href="/"
        className="flex flex-row items-center h-10 px-3 font-poppins  rounded-lg text-gray-100 hover:bg-yellow-500 hover:text-gray-700"
      >
        <span className="flex items-center justify-center text-lg text-gray-400">
          <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-5 w-5 text-gray-100"
          >
            <path
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        </span>
        <Link to='/companies'><span className="ml-3">Company</span></Link>
        <ChevronDownIcon className='w-4 h-6 text-gray-100'/>
      </a>
      
    </li>

    {/* <li className="my-px">
      <a
        href="/"
        className="flex flex-row items-center h-10 px-3 font-poppins  rounded-lg text-gray-100 hover:bg-yellow-500 hover:text-gray-700"
      >
        <span className="flex items-center justify-center text-lg text-gray-400">
          <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-5 w-5 text-gray-100"
          >
            <path
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        </span>
        <Link to='/createauthorcategory'><span className="ml-3">Blog Aut/Cat</span></Link>
        <ChevronDownIcon className='w-4 h-6 text-gray-100'/>
      </a>
      
    </li>

    <li className="my-px">
      <a
        href="/"
        className="flex flex-row items-center h-10 px-3 font-poppins  rounded-lg text-gray-100 hover:bg-yellow-500 hover:text-gray-700"
      >
        <span className="flex items-center justify-center text-lg text-gray-400">
          <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-5 w-5 text-gray-100"
          >
            <path
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        </span>
        <Link to='/postblog'><span className="ml-3">Create Blog</span></Link>
        <ChevronDownIcon className='w-4 h-6 text-gray-100'/>
      </a>
      
    </li>

    <li className="my-px">
      <a
        href="/"
        className="flex flex-row items-center h-10 px-3 font-poppins  rounded-lg text-gray-100 hover:bg-yellow-500 hover:text-gray-700"
      >
        <span className="flex items-center justify-center text-lg text-gray-400">
          <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-5 w-5 text-gray-100"
          >
            <path
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        </span>
        <Link to='/mypost'><span className="ml-3">Blog Content</span></Link>
        <ChevronDownIcon className='w-4 h-6 text-gray-100'/>
      </a>
      
    </li> */}

    <li className="my-px" onClick={toggling}>
      <p
        className="flex flex-row items-center h-10 px-3 font-poppins  rounded-lg text-gray-100 hover:bg-yellow-500 hover:text-gray-700"
      >
        <span className="flex items-center justify-center text-lg text-gray-400">
          <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-5 w-5 text-gray-100"
          >
            <path
              d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        </span>
        <span className="ml-3" >Blog</span>
        <ChevronDownIcon className='w-4 h-6 text-gray-100'/>
      </p>

      
        <div className={open ? "z-10 bg-white divide-y divide-gray-100 rounded shadow z-50 w-30 dark:bg-gray-700" : "hidden z-10 bg-white divide-y divide-gray-100 rounded shadow z-50 w-30 dark:bg-gray-700"}>
          <ul class="py-1 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefault">
            <li>
            <Link to='/createauthorcategory'><p class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Create Cat/Aut</p></Link>
            </li>
            <li>
              <Link to='/postblog'><p class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Create Post</p></Link>
            </li>
            <li>
            <Link to='/mypost'><p class="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">Blog Content</p></Link>
            </li>
          </ul>
        </div>
        
    </li>


    <li className="my-px">
      <span className="flex font-medium font-poppins  text-sm text-gray-300 px-4 my-4 uppercase">Account</span>
    </li>
  
    <li className="my-px" onClick={handleLogout}>
      <a
        href="/"
        className="flex flex-row items-center h-10 px-3 font-poppins  rounded-lg text-gray-100 hover:bg-yellow-500 hover:text-gray-700"
      >
        <span className="flex items-center justify-center text-lg text-red-400">
          <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-5 w-5 text-gray-100 "
          >
            <path
              d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z"
            />
          </svg>
        </span>
        <span className="ml-3">Logout</span>
      </a>
    </li>
  </ul>
</div>
</aside>

    )
}