import React from 'react'
import { ProjectTable } from '../components/ProjectTable'




export const Projects =()=>{
    return(
        <div>
            
            <ProjectTable/>
        </div>
    )
}