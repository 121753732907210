import React from 'react'
import { DevTable } from '../components/DevTable'

export const Developers =()=>{
    return(
        <div>
            
            <DevTable/>
        </div>
    )
}

